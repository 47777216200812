import { onError } from "@apollo/client/link/error";
import { datadogRum } from "@datadog/browser-rum";

type Result = {
  errors: Array<{ detail: string }>;
};

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      datadogRum.addError(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );

      if (
        extensions?.detail &&
        (extensions.detail as string).includes("1501")
      ) {
        handleNoAccess();
      }
    });
});

const handleNoAccess = () => {
  if (!window.location.pathname.startsWith("/no_access")) {
    window.location.replace("/no_access");
  }
};
